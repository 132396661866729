import * as React from "react"
import ShowNewClaimedNft from "../../components/show-newclaimed-nft";
import Layout from "../../components/layout"
import Seo from "../../components/seo"
const  tinyRainBoot = ( props ) => {

  const address = props.params.address; 
  const searchParams = new URLSearchParams(props.location.search);
  const encodedTitle = searchParams.get('title');
  const decodedTitle = decodeURIComponent(encodedTitle);
  return (
      <Layout>
        <section className='section-title white collection artwork'>
          <Seo title="Playground - The Contours of a Dream" />
          <div className='container clearfix flex'>
              <ShowNewClaimedNft tokenId={address} collectionName="The Contours of a Dream" imageUrl="https://tinyrainboot.0kai-cdn.com/image/3/#token_id/1200/800.png" byArtist="Tinyrainboot"  collectionUrl="/tinyrainboot/" imageName={decodedTitle}/>
          </div>
        </section>
      </Layout>
  );
}

export default tinyRainBoot
